const HOME_BG = {
    Intro: require("./home/Intro.mp4"),
    IntroMob: require("./home/IntroMob.mp4"),
    Intro2: require("./home/BG_Intro.png"),
    About: require("./home/BG_About_TrustedBy.png"),
    Featured_Keto: require("./home/BG_FW_Keto.png"),
    Featured_RealEstate: require("./home/BG_FW_RealEstate.png"),
    Featured_FutureApps: require("./home/BG_FW_FutureApps.png"),
    Featured_MicroDelights: require("./home/BG_FW_MicroDelights.png"),
    Blog: require("./home/BG_Blog.jpg"),
}

const HOME_ICONS = {
    Website: require("./home/Icon.png"),
    Call: require("./home/Icon_Call.png"),
    About: require("./home/Icon_About.png"),
    RightArrow: require("./home/Icon_RightArrow.png"),
    Menu: require("./home/Icon_Menu.png"),
    Keto: require("./home/Icon_Keto.png"),
    FutureApps: require("./home/Icon_FutureApps.png"),
    Heart: require("./home/Icon_Heart.png"),
    Brain: require("./home/Icon_Brain.png"),
    Finger: require("./home/Icon_Finger.png"),
    Whatsapp: require("./home/Icon_Whatsapp.png"),
}

const HOME_IMAGES = {
    MicroDelights: require("./home/Image_FW_MicroDelight.png"),
    RealEstate: require("./home/Image_FW_RealEstate.png"),
}

const PORTFOLIO_IMAGES = {
    Ketobalanced: require("./portfolio/keto.png"),
    Keto_BG: require("./portfolio/Keto-bg.png"),
    hBits: require("./portfolio/hbits.png"),
    iqvia: require("./portfolio/iqvia.png"),
    celestial: require("./portfolio/celestial.png"),
    ace: require("./portfolio/ace.png"),
    KotakBank: require("./portfolio/kotak_net.png"),
    yum: require("./portfolio/yum.png"),
    KotakLoan: require("./portfolio/kotak.png"),
    yippee: require("./portfolio/yipee.png"),
    kotakBill: require("./portfolio/kotak_bill.png"),
    vectorflow: require("./portfolio/vectorflow.png"),
    form_error: require("./portfolio/form/error.png"),
    form_success: require("./portfolio/form/success.png"),
}

const KETO_IMAGES = {
    age: require("./portfolio/Keto/age.png"),
    bg: require("./portfolio/Keto/bg.png"),
    cal: require("./portfolio/Keto/cal.png"),
    cover: require("./portfolio/Keto/cover.png"),
    cover_phone: require("./portfolio/Keto/cover_phone.png"),
    gender: require("./portfolio/Keto/gender.png"),
    diet: require("./portfolio/Keto/diet.png"),
    home: require("./portfolio/Keto/home.png"),
    ketosis: require("./portfolio/Keto/ketosis.png"),
    ketosis2: require("./portfolio/Keto/ketosis2.png"),
    landing: require("./portfolio/Keto/landing.png"),
    meals: require("./portfolio/Keto/meals.png"),
    mood: require("./portfolio/Keto/mood.png"),
    mood2: require("./portfolio/Keto/mood2.png"),
    quiz: require("./portfolio/Keto/quiz.png"),
    step: require("./portfolio/Keto/step.png"),
    store_apple: require("./portfolio/Keto/store_apple.png"),
    store_google: require("./portfolio/Keto/store_google.png"),
    summary: require("./portfolio/Keto/summary.png"),
    swap: require("./portfolio/Keto/swap.png"),
    water: require("./portfolio/Keto/water.png"),
    weight: require("./portfolio/Keto/weight.png"),
    weight2: require("./portfolio/Keto/weight2.png"),
}

const HBITS_IMAGES = {
    intro: require("./portfolio/hbits/intro.png"),
    cover: require("./portfolio/hbits/cover.jpg"),
    landing1: require("./portfolio/hbits/landing1.png"),
    landing2: require("./portfolio/hbits/landing2.png"),
    landing3: require("./portfolio/hbits/landing3.png"),
    landing4: require("./portfolio/hbits/landing4.png"),
    plant: require("./portfolio/hbits/plant.png"),
    insp1: require("./portfolio/hbits/insp1.png"),
    insp2: require("./portfolio/hbits/insp2.png"),
    insp3: require("./portfolio/hbits/insp3.png"),
    design1: require("./portfolio/hbits/design1.png"),
    design2: require("./portfolio/hbits/design2.png"),
    web1: require("./portfolio/hbits/web1.png"),
    web2: require("./portfolio/hbits/web2.png"),
    web3: require("./portfolio/hbits/web3.png"),
    web4: require("./portfolio/hbits/web4.png"),
    mobile1: require("./portfolio/hbits/mobile1.png"),
    mobile2: require("./portfolio/hbits/mobile2.png"),
    mobile3: require("./portfolio/hbits/mobile3.png"),
    mobile4: require("./portfolio/hbits/mobile4.png"),
    mobile5: require("./portfolio/hbits/mobile5.png"),
    mobile6: require("./portfolio/hbits/mobile6.png"),
    social1: require("./portfolio/hbits/social1.png"),
    social2: require("./portfolio/hbits/social2.png"),
    social3: require("./portfolio/hbits/social3.png"),
    social4: require("./portfolio/hbits/social4.png"),
    social5: require("./portfolio/hbits/social5.png"),
    social6: require("./portfolio/hbits/social6.png"),
    social7: require("./portfolio/hbits/social7.png"),
}

const IQVIA_IMAGES = {
    cover: require("./portfolio/iqvia/cover.jpg"),
    landing1: require("./portfolio/iqvia/landing1.png"),
    landing2: require("./portfolio/iqvia/landing2.png"),
    landing3: require("./portfolio/iqvia/landing3.png"),
    atom: require("./portfolio/iqvia/atom.png"),
    design1: require("./portfolio/iqvia/design1.png"),
    design2: require("./portfolio/iqvia/design2.png"),
    web1: require("./portfolio/iqvia/web1.png"),
    web2: require("./portfolio/iqvia/web2.png"),
    web3: require("./portfolio/iqvia/web3.png"),
    web4: require("./portfolio/iqvia/web4.png"),
    web5: require("./portfolio/iqvia/web5.png"),
    mobile1: require("./portfolio/iqvia/mobile1.png"),
    mobile2: require("./portfolio/iqvia/mobile2.png"),
    mobile3: require("./portfolio/iqvia/mobile3.png"),
    mobile4: require("./portfolio/iqvia/mobile4.png"),
    mobile5: require("./portfolio/iqvia/mobile5.png"),
    mobile6: require("./portfolio/iqvia/mobile6.png"),
    mobile7: require("./portfolio/iqvia/mobile7.png"),
    mobile8: require("./portfolio/iqvia/mobile8.png"),
    mobile9: require("./portfolio/iqvia/mobile9.png"),
    mobile10: require("./portfolio/iqvia/mobile10.png"),
    download1: require("./portfolio/iqvia/download1.png"),
    download2: require("./portfolio/iqvia/download2.png"),
}

const KOTAK_LOANS_IMAGES = {
    cover: require("./portfolio/kotakloans/cover.jpg"),
    landing1: require("./portfolio/kotakloans/landing1.png"),
    landing2: require("./portfolio/kotakloans/landing2.png"),
    pouch: require("./portfolio/kotakloans/pouch.png"),
    web1: require("./portfolio/kotakloans/web1.png"),
    web2: require("./portfolio/kotakloans/web2.png"),
    web3: require("./portfolio/kotakloans/web3.png"),
    web4: require("./portfolio/kotakloans/web4.png"),
    web5: require("./portfolio/kotakloans/web5.png"),
    web6: require("./portfolio/kotakloans/web6.png"),
    web7: require("./portfolio/kotakloans/web7.png"),
    web8: require("./portfolio/kotakloans/web8.png"),
    mobile1: require("./portfolio/kotakloans/mobile1.png"),
    mobile2: require("./portfolio/kotakloans/mobile2.png"),
    mobile3: require("./portfolio/kotakloans/mobile3.png"),
    mobile4: require("./portfolio/kotakloans/mobile4.png"),
    mobile5: require("./portfolio/kotakloans/mobile5.png"),
    mobile6: require("./portfolio/kotakloans/mobile6.png"),
    mobile7: require("./portfolio/kotakloans/mobile7.png"),
    mobile8: require("./portfolio/kotakloans/mobile8.png"),
    mobile9: require("./portfolio/kotakloans/mobile9.png"),
    mobile10: require("./portfolio/kotakloans/mobile10.png"),
    mobile11: require("./portfolio/kotakloans/mobile11.png"),
    mobile12: require("./portfolio/kotakloans/mobile12.png"),
}

const CELESTIAL_IMAGES = {
    cover: require("./portfolio/celestial/cover.jpg"),
    landing: require("./portfolio/celestial/landing.png"),
    mountain: require("./portfolio/celestial/mountain.png"),
    mobile1: require("./portfolio/celestial/mobile1.png"),
    mobile2: require("./portfolio/celestial/mobile2.png"),
    mobile3: require("./portfolio/celestial/mobile3.png"),
    mobile4: require("./portfolio/celestial/mobile4.png"),
    mobile5: require("./portfolio/celestial/mobile5.png"),
    mobile6: require("./portfolio/celestial/mobile6.png"),
    mobile7: require("./portfolio/celestial/mobile7.png"),
    bg: require("./portfolio/celestial/bg.png"),
}

const KOTAK_NET_IMAGES = {
    cover: require("./portfolio/kotaknet/cover.jpg"),
    landing1: require("./portfolio/kotaknet/landing1.png"),
    landing2: require("./portfolio/kotaknet/landing2.png"),
    landing3: require("./portfolio/kotaknet/landing3.png"),
    info: require("./portfolio/kotaknet/info.png"),
    video1: require("./portfolio/kotaknet/v1.mp4"),
    video2: require("./portfolio/kotaknet/v2.mp4"),
    video3: require("./portfolio/kotaknet/v3.mp4"),
    video4: require("./portfolio/kotaknet/v4.mp4"),
    video5: require("./portfolio/kotaknet/v5.mp4"),
    video6: require("./portfolio/kotaknet/v6.mp4"),
    video7: require("./portfolio/kotaknet/v7.mp4"),
}

const ACE_IMAGES = {
    bg: require("./portfolio/ace/bg.jpg"),
    cover: require("./portfolio/ace/cover.jpg"),
    landing: require("./portfolio/ace/landing.png"),
    card: require("./portfolio/ace/card.png"),
    mobile1: require("./portfolio/ace/mobile1.png"),
    mobile2: require("./portfolio/ace/mobile2.png"),
    mobile3: require("./portfolio/ace/mobile3.png"),
    mobile4: require("./portfolio/ace/mobile4.png"),
    mobile5: require("./portfolio/ace/mobile5.png"),
}

const VECTOR_FLOW_IMAGES = {
    cover: require("./portfolio/vectorflow/cover.jpg"),
    landing1: require("./portfolio/vectorflow/landing1.png"),
    landing2: require("./portfolio/vectorflow/landing2.png"),
    idea: require("./portfolio/vectorflow/idea.png"),
    mobile1: require("./portfolio/vectorflow/mobile1.png"),
    mobile2: require("./portfolio/vectorflow/mobile2.png"),
    mobile3: require("./portfolio/vectorflow/mobile3.png"),
    mobile4: require("./portfolio/vectorflow/mobile4.png"),
    mobile5: require("./portfolio/vectorflow/mobile5.png"),
    mobile6: require("./portfolio/vectorflow/mobile6.png"),
    web1: require("./portfolio/vectorflow/web1.png"),
    web2: require("./portfolio/vectorflow/web2.png"),
    web3: require("./portfolio/vectorflow/web3.png"),
    web4: require("./portfolio/vectorflow/web4.png"),
    web5: require("./portfolio/vectorflow/web5.png"),
    web6: require("./portfolio/vectorflow/web6.png"),
}

const BLOG_IMAGES = {
    Prev: require("./blogs/prev.png"),
    Next: require("./blogs/next.png"),
    Time: require("./blogs/time.png"),
    Share: require("./blogs/share.png"),
}

const ABOUT_IMAGES = {
    bg1: require("./about/bg.png"),
    bg2: require("./about/bg2.png"),
    bg3: require("./about/bg3.png"),
    asteroid1: require("./about/asteroid1.png"),
    asteroid2: require("./about/asteroid2.png"),
    asteroid3: require("./about/asteroid3.png"),
    planet: require("./about/planet.png"),
    light: require("./about/light.png"),
    galaxy: require("./about/galaxy.png"),
    planet2: require("./about/planet2.png"),
    light2: require("./about/light2.png"),
    light3: require("./about/light3.png"),
    iceberg: require("./about/iceberg.png"),
    water: require("./about/water.png"),
}

const VIDEOS = {
    border: require("./videos/Mobile_Border.png"),
    keto_short: require("./videos/Keto_Short.mp4"),
    keto_long: require("./videos/Keto_Long.mp4"),
    celestial_short: require("./videos/Celestial_Short.mp4"),
    celestial_long: require("./videos/Celestial_Long.mp4"),
    ace_pay: require("./videos/AcePay_Long.mp4"),
}

const SOCIALS = {
    fb: require("./socials/facebook.png"),
    ig: require("./socials/instagram.png"),
    ld: require("./socials/linkedin.png"),
    yt: require("./socials/youtube.png"),
}

export {
    VIDEOS,
    HOME_BG,
    HOME_ICONS,
    HOME_IMAGES,
    BLOG_IMAGES,
    PORTFOLIO_IMAGES,
    KETO_IMAGES,
    HBITS_IMAGES,
    IQVIA_IMAGES,
    KOTAK_LOANS_IMAGES,
    CELESTIAL_IMAGES,
    KOTAK_NET_IMAGES,
    ACE_IMAGES,
    VECTOR_FLOW_IMAGES,
    ABOUT_IMAGES,
    SOCIALS,
}
